<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <h1 class="item_title mt-3 mb-6 ml-0">{{data.name}}</h1>

      <v-text-field
        label="Введите число.."
        v-model="numVal"
        filled
        single-line
        counter="10"
        type="number"
        hide-details="auto"
        @change="saveData"
        :rules="numberRule"
      ></v-text-field>
    </v-col>

  </v-row>
</template>

<script>
    export default {
        name: 'TextField',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.numVal = null
            }
        },
        data () {
            return {
                numberRule: [
                    value => this.check(value)
                ],
                data: this.item,
                maxAge: (this.item.conf.maxage) ? parseInt(this.item.conf.maxage) : 999,
                minAge: (this.item.conf.minage) ? parseInt(this.item.conf.minage) : 0,
                numVal: ''
            }
        },
        methods: {
            saveData: function () {
                this.$store.dispatch('saveSignItem', { id: this.item.id, value: this.numVal, type: 'numfield', check: this.check(this.numVal) })
            },
            check: function(value) {
                return (!value.trim() || !this.item.conf.fill) ? 'поле должно содержать цифры и не может быть пустым' : (!isNaN(parseFloat(value)) && value >= this.minAge && value <= this.maxAge) ? true : 'Введите целое число от ' + this.minAge + ' до ' + this.maxAge
            }
        },
        computed: {
            val: function() {
                const a = this.$store.getters.currentSign.find(item => {
                    return (item.id === this.data.id)
                })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            }
        },
        mounted() {
            this.numVal = this.val
        }
    }
</script>

<style scoped>

</style>
