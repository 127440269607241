<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <h1 class="item_title ma-3 mb-2 pb-5 ml-0">{{data.name}}</h1>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="DateFormatted"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Выберите дату"
            prepend-icon="mdi-calendar"
            v-model="DateFormatted"
            readonly
            filled
            single-line
            hint="MM/DD/YYYY формат"
            counter="0"
            :rules="rules"
            hide-details="auto"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          @input="menu = false"
          :first-day-of-week="1"
          locale="ru-ru"
          @change="saveData"
        ></v-date-picker>
      </v-menu>

    </v-col>

  </v-row>
</template>

<script>
    import moment from 'moment'
    export default {
        name: 'DatePicker',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.date = null
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                date: null,
                menu: false
            }
        },
        methods: {
            saveTitle: function () {
                const value = { number: this.number, name: this.data.name }
                this.$store.dispatch('setAnketTitle', value)
            },
            saveData: function () {
                this.$store.dispatch('saveSignItem', { id: this.item.id, value: this.date, type: 'datepicker', check: true })
            }
        },
        computed: {
            DateFormatted() {
                return this.date ? moment(this.date).locale('ru').format('Do MMMM YYYY, dddd') : ''
            },
            val: function() {
                const a = this.$store.getters.currentSign.find(item => { return (item.id === this.data.id) })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            }
        },
        mounted() {
            this.date = this.val
        }
    }
</script>

<style scoped>

</style>
