<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-6 pb-8">

      <h1 class="item_title m-3">{{data.name}}</h1>

          <v-list class="pt-4 mt-0">
              <v-list-item v-for="(sel, i) in selItems" :key="'sel'+i" class="selitem">

                <v-checkbox
                  v-model="check"
                  color="info"
                  hide-details="true"
                  class="mt-0 mb-0 pb-2"
                  @change="saveData"
                  :value="sel"
                  multiple
                >
                  <template v-slot:label>
                    <div class="selitem_title">
                      {{sel}}
                      <v-menu
                    :close-on-content-click="false"
                    :nudge-width="200"
                    nudge-right="10"
                    offset-x
                    v-if="itemsDescription"
                    >
                    <template v-slot:activator="{ on }">
                        <v-icon class="mr-2" v-on="on">mdi-information</v-icon>
                    </template>
                        <v-card>
                            <h3 class="pa-5">Предметы в направлении</h3>
                            <v-divider />
                            <v-list dense>
                                <v-list-item v-for="pred, j in getList(itemsDescription[i])" :key="'p'+j" class="pl-5 py-0 my-0">
                                    <v-icon>mdi-dot</v-icon>
                                    {{ pred }}
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                    </div>
                  </template>

                </v-checkbox>

              </v-list-item>
          </v-list>
    </v-col>
  </v-row>
</template>

<script>

    export default {
        name: 'CheckList',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.check = []
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                selItems: (this.item.variants) ? this.item.variants : [],
                menu: [],
                check: []
            }
        },
        methods: {
          saveData: function () {
              if (this.answers) {
                if (this.check.length > this.answers) this.check.splice(this.answers - 1, 1)
              }
              this.$store.dispatch('saveSignItem', { id: this.item.id, value: this.check, type: 'checklist', check: true })
          },
            getList(data) {
                return data.split(';')
            }
        },
        computed: {
            val: function() {
                const a = this.$store.getters.currentSign.find(item => { return (item.id === this.data.id) })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            },
            answers: function() {
              return (this.item.conf.answers) ? this.item.conf.answers : null
            },
            itemsDescription: function() {
                return (this.data.description) ? JSON.parse(this.data.description).items : null
            }
        },
        mounted() {
            this.check = (this.val) ? [...this.val] : []
        }
    }
</script>

<style scoped>
  .selitem:hover {
    background-color: #eee;
  }

  .selitem_title {
    font-size: 20px;
    font-weight: 400;
    color: #444;
    margin-left: 4px;
  }

  .link {
    font-size: 16px;
  }

  .link:hover {
    color: #295bb5;
  }

  .moveSelItem{
    cursor: move;
  }

</style>
