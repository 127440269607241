<template>
  <v-row>
    <v-col cols="12" class="mt-3 ml-3 mr-0 pl-8 pt-6 pb-0">
        <h1 class="item_title ma-2 ma-md-0">{{data.name}}</h1>
    </v-col>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-0 pt-6 pb-8">
        <v-list class="pt-4 mt-0 left-column" v-if="paintSelItems">
            <v-list-item v-for="(item, i) in items" :class="(paintSelItems[i]) ? 'selitem_check' : 'selitem' " :key="'items'+i" @click="setPlace(i)">
                <div class="selitem_title">
                    <v-menu
                    v-model="menu[i]"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    nudge-right="10"
                    offset-x
                    v-if="itemsDescription"
                    >
                    <template v-slot:activator="{ on }">
                        <v-icon class="mr-2" v-on="on">mdi-information</v-icon>
                    </template>
                        <v-card>
                            <h3 class="pa-5">Предметы в направлении</h3>
                            <v-divider />
                            <v-list dense>
                                <v-list-item v-for="pred, j in getList(itemsDescription[i])" :key="'p'+j" class="pl-5 py-0 my-0">
                                    <v-icon>mdi-dot</v-icon>
                                    {{ pred }}
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                    {{item}}</div>
            </v-list-item>
        </v-list>
    </v-col>
    <v-col cols="12" md="6" class="mt-0 ml-3 ml-md-0 mr-12 mr-md-3 pl-8 pl-md-0 pr-8 plt-md-0 pt-md-6 pb-8">
        <div class="numbers float-left">
            <v-list class="pt-0 pt-md-4 mt-0">
                <v-list-item v-for="(nums, i) in items" :key="'nums'+i" class="selitem2">
                    {{ parseInt(i)+1 }}.
                </v-list-item>
            </v-list>
        </div>
        <v-list class="pt-0 pt-md-4 mt-0 pr-5 pr-md-0">
                <v-list-item class="selitem2" v-for="(sel, i) in selItems" :key="'nums'+i" @click="clearPlace(i)">
                        {{ sel }}
                </v-list-item>
        </v-list>
    </v-col>
  </v-row>
</template>

<script>

    export default {
        name: 'CheckList',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) {
                    this.check = []
                    this.selItems = [...Array(this.items.length).fill(null)]
                    this.menu = [...Array(this.items.length).fill(false)]
                    this.selItems.forEach((item, i) => {
                            this.paintSelItems[i] = (item)
                    })
                }
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                items: (this.item.variants) ? [...this.item.variants] : [],
                selItems: [],
                check: [],
                pos: 0,
                checkName: false,
                menu: [],
                paintSelItems: []
            }
        },
        methods: {
            saveData: function () {
              this.$store.dispatch('saveSignItem', { id: this.item.id, value: this.selItems, type: 'rating', check: true })
            },
            setPlace(i) {
                this.pos = -1
                this.checkName = false
                this.selItems.forEach((item, j) => {
                    if ((!item) && (this.pos === -1)) {
                        this.pos = j
                    }
                    if (item === this.items[i]) this.checkName = true
                })
                if (!this.checkName) {
                    this.$set(this.selItems, this.pos, this.items[i])
                    this.saveData()
                    this.paintSelItems[i] = true
                }
            },
            clearPlace(i) {
                const j = this.items.findIndex(item => (item === this.selItems[i]))
                this.$set(this.selItems, i, '')
                this.saveData()
                this.paintSelItems[j] = false
            },
            getList(data) {
                return data.split(';')
            }
        },
        computed: {
            val: function() {
                const a = this.$store.getters.currentSign.find(item => { return (item.id === this.data.id) })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            },
            itemsDescription: function() {
                return (this.data.description) ? JSON.parse(this.data.description).items : null
            }
        },
        mounted() {
            this.check = (this.val) ? [...this.val] : []
            this.selItems = (this.val) ? [...this.val] : [...Array(this.items.length).fill(null)]
            this.menu = (this.data.description) ? [...Array(this.items.length).fill(false)] : []
            this.selItems.forEach((item, i) => {
                    this.paintSelItems[i] = (item)
            })
        }
    }
</script>

<style scoped>
  .selitem {
    background-color: #ddd;
    margin: 4px;
  }

  .selitem_check {
    background-color: #c7dfbd;
    margin: 4px;
  }

  .selitem2 {
    background-color: #c4e8f3;
    margin: 4px;
  }

  .selitem:hover {
    background-color: #ccc;
  }

  .selitem_title {
    font-size: 20px;
    font-weight: 400;
    color: #444;
    margin-left: 0px;
  }

  .link {
    font-size: 16px;
  }

  .link:hover {
    color: #295bb5;
  }

  .moveRatingItem {
    cursor: pointer !important;
  }

  .numbers {
    width: 60px;
  }
</style>
