<template>
    <v-row>
        <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">
            <h1 class="item_title mt-3 mb-6 ml-0">{{data.name}}</h1>
            <v-file-input
                label="Загрузите документ.."
                v-model="content"
                filled
                single-line
                @change="saveData"
                :rules="rules"
                :error="error"
                v-show="!content"
            ></v-file-input>
            <v-row v-show="content" class="mb-5">
                <v-col>
                    <v-icon class="mr-4">mdi-link</v-icon>
                    <a :href="link+'/storage/app/'+content" class="link" target="_blank">документ загружен >></a>
                </v-col>
                <v-col class="text-right">
                    <v-btn small @click="content = ''">заменить документ</v-btn>
                </v-col>
            </v-row>
        </v-col>
  </v-row>
</template>

<script>
    export default {
        name: 'Document',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.content = null
            }
        },
        data () {
            return {
                rules: [
                    value => this.check(value)
                ],
                data: this.item,
                content: null
            }
        },
        methods: {
            saveData: function () {
                this.$store.dispatch('saveFile', { id: this.item.id, idUnique: this.anketaId, value: this.content, type: 'document' }).then(response => {
                    this.$store.dispatch('saveSignItem', { id: this.item.id, value: response.data, type: 'document', check: this.check(response.data) })
                    this.content = response.data
                })
            },
            check: function(value) {
                return (value || !this.item.conf.fill) ? true : 'поле обязательноe для заполнения'
            }
        },
        computed: {
            val: function() {
                const a = this.$store.getters.currentSign.find(item => { return (item.id === this.data.id) })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            },
            error: function() {
                return !(this.content || !this.item.conf.fill)
            },
            link: function() {
                return this.$store.getters.link
            }
        },
        mounted() {
            this.content = this.val
        }
    }
</script>

<style scoped>

    .link {
        text-decoration: none !important;
    }

    .link:hover {
        color: #ff0000;
    }

</style>
