<template>
  <v-row>
    <v-col class="mt-4 ml-3 mr-3 pl-8 pr-8 pt-2 pb-2">
      <bubble-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
          <v-btn elevation="2" style="background-color: rgba(255,255,255,1)" icon tile class="mr-2">
            <v-icon>
              mdi-format-bold
            </v-icon>
          </v-btn>
        </button>

        <button @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }">
          <v-btn elevation="2" style="background-color: rgba(255,255,255,1)" icon tile class="mr-2">
            <v-icon>
              mdi-format-italic
            </v-icon>
          </v-btn>
        </button>
        <button @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
          <v-btn elevation="2" style="background-color: rgba(255,255,255,1)" icon tile class="mr-2">
            <v-icon>
              mdi-format-align-left
            </v-icon>
          </v-btn>
        </button>
        <button @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
          <v-btn elevation="2" style="background-color: rgba(255,255,255,1)" icon tile class="mr-2">
            <v-icon>
              mdi-format-align-center
            </v-icon>
          </v-btn>
        </button>
        <button @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
          <v-btn elevation="2" style="background-color: rgba(255,255,255,1)" icon tile class="mr-2">
            <v-icon>
              mdi-format-align-right
            </v-icon>
          </v-btn>
        </button>
        <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
          <v-btn elevation="2" style="background-color: rgba(255,255,255,1)" icon tile class="mr-2">
            <v-icon>
              mdi-link
            </v-icon>
          </v-btn>
        </button>
        <button @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
          <v-btn elevation="2" style="background-color: rgba(255,255,255,1)" icon tile class="mr-2">
            <v-icon>
              mdi-link-off
            </v-icon>
          </v-btn>
        </button>
      </bubble-menu>
      <editor-content :editor="editor" />
    </v-col>
  </v-row>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'

import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'

export default {
  name: 'DescriptionArea',
  props: ['item', 'number'],
  components: {
    EditorContent,
    BubbleMenu
  },
  data() {
    return {
      editor: null,
      data: this.item
    }
  },
  mounted() {
    this.editor = new Editor({
      content: (this.data.name) ? this.data.name : 'Здесь можно писать текст',
      onUpdate: ({ editor }) => {
                    this.saveText(this.editor.getHTML())
      },
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Link.configure({
          openOnClick: true
        })
      ]
    })
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }
      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    saveText: function (text) {
      const value = { number: this.number, name: text }
      this.$store.dispatch('setAnketTitle', value)
    }
  },
  beforeUnmount() {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.ProseMirror:focus {
  outline: none;
}

.tippy-content div {
  opacity: 1 !important;
  visibility: visible !important;
}
</style>
