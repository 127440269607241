<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <h1 class="item_title mt-3 mb-6 ml-0">{{data.name}}</h1>

      <v-select
        label="Выберите вариант"
        :items="selItems"
        outlined
        single-line
        no-data-text=""
        v-model="selItem"
        @change="saveData"
      >
      </v-select>

    </v-col>

  </v-row>
</template>

<script>

    export default {
        name: 'SelectField',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.selItem = null
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                selItems: (this.item.variants) ? this.item.variants : ['', ''],
                selItem: null
            }
        },
        methods: {
            saveData: function () {
                this.$store.dispatch('saveSignItem', { id: this.item.id, value: this.selItem, type: 'selectfield', check: true })
            },
            toggle: function (i) {
               this.selItem = i
            }
        },
        computed: {
            val: function() {
                const a = this.$store.getters.currentSign.find(item => { return (item.id === this.data.id) })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            }
        },
        mounted() {
            this.selItem = this.val
        }
    }
</script>

<style scoped>
  .selitem:hover {
    background-color: #eee;
  }

  .selitem_title {
    font-size: 16px;
    color: #333;
  }

  .link {
    font-size: 16px;
  }

  .link:hover {
    color: #295bb5;
  }

  .moveSelItem{
    cursor: move;
  }

</style>
