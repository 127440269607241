import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Dexie from 'dexie'

import { signStore } from './sign'
import { authStore } from './auth'
Vue.use(Vuex)
axios.defaults.withCredentials = true

const link = (process.env.NODE_ENV === 'development') ? 'http://localhost/kodo/kodo.api' : 'https://api.kodo.kkr.ru'

axios.get(link + '/sanctum/csrf-cookie').then(response => {
})

export const db = new Dexie('kodoformDB')
db.version(1).stores({
  store: '++id, name, slug, status, pages, signCount'
})

export default new Vuex.Store({
  state: {
    ankets: [],
    signs: {},
    anketId: 0,
    anketNum: 0,
    anketPageNumber: 0,
    itemsPerPage: 10,
    anketItems: [],
    anketBlank: [{
      status: false,
      id: 0,
      name: 'Новая анкета',
      pages: [{
        id: 0,
        items: [
          {
            id: 0,
            name: 'Заголовок анкеты',
            type: 'headerarea'
          },
          {
            id: 0,
            name: 'Вопрос 1',
            type: 'textfield',
            conf: {}
          }
        ]
      }],
      closed: 0
    }],
    regions: [],
    ecc: [],
    results: {},
    link: link
  },

  getters: {
    currentAnket: state => state.ankets[state.anketNum],
    currentAnketId: state => state.anketId,
    currentAnketStatus: state => (state.ankets.length) ? state.ankets[state.anketNum].status : null,
    currentAnketSlug: state => (state.ankets.length) ? state.ankets[state.anketNum].slug : null,
    currentPage: state => state.ankets[state.anketNum].pages[state.anketPageNumber],
    currentSignResult: state => state.signs[state.anketId],
    anketItems: state => state.anketItems,
    currentItemsPerPage: state => state.itemsPerPage,
    allAnkets: state => state.ankets,
    allRegions: state => state.regions,
    allEcc: state => state.ecc,
    allResults: state => state.results,
    link: state => state.link
  },

  mutations: {
    GET_ANKET_BY_ID: (state) => {
      // if (state.anketId) {
        state.ankets.filter((item, i) => {
          if (item.id === state.anketId) {
            state.anketNum = i
            return true
          }
        })
     // } else state.anket = state.ankets[state.anketNum]
    },
    GET_ANKET_BY_NUM: (state) => {
      const num = parseInt(localStorage.getItem('kodo-store-anket-num'))
      if (num) {
        state.anketNum = num
      }
      state.anketId = state.ankets[state.anketNum].id
    },
    GET_ANKETS: (state, ankets) => {
      state.ankets = ankets
    },
    GET_ANKET_ITEMS: (state, items) => {
      state.anketItems = items
    },
    GET_REGIONS: (state, data) => {
      state.regions = data
    },
    GET_ECC: (state, data) => {
      state.ecc = data
    },
    SET_ANKET_PAGE: (state, num) => {
      state.anketPageNumber = num
    },
    CHANGE_ANKET: (state, anket) => {
      state.ankets[state.anketNum].status = false
      Vue.set(state.ankets[state.anketNum].pages[state.anketPageNumber], 'items', anket)
    },
    CHANGE_ANKET_ID: (state, data) => {
      state.anketId = data.id
      state.anketPageNumber = 0
      state.anketNum = data.number
      localStorage.setItem('kodo-store-anket-num', state.anketNum)
    },
    NEW_ANKET: (state, anket) => {
      state.ankets.push(anket.data[0])
      state.anketPageNumber = 0
      Vue.set(state, 'anketId', anket.id)
      state.anketNum = 0
      localStorage.setItem('kodo-store-anket-num', state.anketNum)
    },
    ADD_PAGE: (state, page) => {
      state.ankets[state.anketNum].status = false
      state.ankets[state.anketNum].pages.push(page)
      db.store.update(state.anketId, { pages: state.ankets[state.anketNum].pages })
    },
    DEL_PAGE: (state, pageNum) => {
      state.ankets[state.anketNum].status = false
      state.ankets[state.anketNum].pages.splice(pageNum, 1)
      state.anketPageNumber--
      db.store.update(state.anketId, { pages: state.ankets[state.anketNum].pages })
    },
    SAVE_ANKET: (state, data) => {
      Vue.set(state.ankets, state.anketNum, data)
      db.store.put(data, state.anketId)
    },
    SAVE_SIGN_LIST: (state, data) => {
      state.signs = data.signs
      Object.keys(data.list).forEach((j) => {
          Object.keys(data.list[j].items).forEach((k) => {
            state.ankets.forEach((anket, a) => {
              if (data.list[j].id === anket.id) {
                state.ankets[a].signCount = data.list[j].count
              }
              anket.pages.forEach((page, p) => {
                page.items.forEach((item, i) => {
                  if (data.list[j].items[k].id === item.id) {
                    if (data.list[j].items[k].answers) {
                      state.ankets[a].pages[p].items[i].answers = data.list[j].items[k].answers
                    }
                  }
                })
              })
            db.store.update(anket.id, { pages: state.ankets[a].pages, signCount: state.ankets[a].signCount })
            })
          })
      })
    },
    SAVE_BIG_SIGN_LIST: (state, data) => {
      Vue.set(state.results, 'list', data.signs)
      Vue.set(state.results, 'count', data.count)
    },
    SAVE_BIG_SIGN_SVOD: (state, data) => {
      Vue.set(state.results, 'svod', data)
    },
    SAVE_SIGN_COUNT_LIST: (state, data) => {
      data.list.forEach((sign, j) => {
          const i = state.ankets.findIndex(item => (parseInt(item.id) === parseInt(sign.id)))
          if (state.ankets[i] !== undefined) state.ankets[i].signCount = data.list[j].count
      })
    },
    CLEAR: (state, data) => {
      db.store.clear()
    },
    DELETE_ANKET: (state, number) => {
      Vue.set(state, 'anketNum', 0)
      localStorage.setItem('kodo-store-anket-num', state.anketNum)
      state.ankets.splice(number, 1)
    },
    SET_ITEMS_PER_PAGE: (state, number) => {
      state.itemsPerPage = number
      localStorage.setItem('kodo-items-per-page', number)
    },
    GET_ITEMS_PER_PAGE: (state) => {
      state.itemsPerPage = localStorage.getItem('kodo-items-per-page')
    }
  },

  actions: {
    getAnkets({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.anketItems.length) {
          axios.post(link + '/forms/items/get').then(response => {
            commit('GET_ANKET_ITEMS', response.data)
          })
        }

        if (!state.ankets.length) {
          axios.post(link + '/forms/anket/get', { user: state.auth.userId }).then(response => {
            if (response.data.length) {
              response.data.forEach(item => {
                db.store.put({ id: item.id, name: item.name, slug: item.slug, status: item.status, pages: item.pages, signCount: item.signCount, closed: item.closed })
              })
              db.store.toArray().then((localResponse) => {
                commit('GET_ANKETS', localResponse)
                resolve()
              })
            } else {
              db.store.toArray().then((localResponse) => {
                if (localResponse.length) {
                  commit('GET_ANKETS', localResponse)
                } else {
                  commit('GET_ANKETS', JSON.parse(JSON.stringify(state.anketBlank)))
                }
                resolve()
              })
            }
          })
        } else {
          db.store.toArray().then((localResponse) => {
            commit('GET_ANKETS', localResponse)
          })
          resolve()
        }
      })
    },
    getAnketResultBySlug({ state, commit }, slug) {
      return new Promise((resolve, reject) => {
        if (!state.anketItems.length) {
          axios.post(link + '/forms/items/get').then(response => {
            commit('GET_ANKET_ITEMS', response.data)
          })
        }
        axios.post(link + '/forms/anket/slug/get', { slug }).then(response => {
              commit('GET_ANKETS', [response.data])
              state.anketNum = 0
              state.anketId = state.ankets[state.anketNum].id
              state.anketPageNumber = 0
              resolve()
        })
      })
    },
    getAnketResultByAnket({ state, commit }, anket) {
      return new Promise((resolve, reject) => {
        if (!state.anketItems.length) {
          axios.post(link + '/forms/items/get').then(response => {
            commit('GET_ANKET_ITEMS', response.data)
            state.anketNum = state.ankets.findIndex(item => item.id === anket.id)
            state.anketId = anket.id
            state.anketPageNumber = 0
            resolve()
          })
        } else {
          state.anketNum = state.ankets.findIndex(item => item.id === anket.id)
          state.anketId = anket.id
          state.anketPageNumber = 0
          resolve()
        }
      })
    },
    newAnket({ state, commit }) {
        db.store.orderBy('id').first().then(item => {
          const newId = ((item.id - 1) > 0) ? 0 : (item.id - 1)
          db.store.put({ id: newId, name: state.anketBlank[0].name, slug: state.anketBlank[0].slug, status: state.anketBlank[0].status, pages: state.anketBlank[0].pages, signCount: state.anketBlank[0].signCount, closed: 0 })
          commit('NEW_ANKET', { data: JSON.parse(JSON.stringify(state.anketBlank)), id: newId })
        })
    },
    getAnketById({ dispatch, state, commit }) {
      commit('GET_ANKET_BY_ID')
    },
    getAnketByNum({ dispatch, state, commit }) {
      commit('GET_ANKET_BY_NUM')
    },
    getSigns({ commit, state }, data) {
      if (!Object.keys(state.signs).length) {
        var ankets = []
        state.ankets.forEach((item) => {
          ankets.push(item.id)
        })
        return new Promise((resolve, reject) => {
          axios.post(link + '/forms/anket/signs', { list: ankets }).then(response => {
            // commit('SAVE_SIGN_LIST', response.data)
            commit('SAVE_SIGN_COUNT_LIST', response.data)
            commit('GET_ITEMS_PER_PAGE')
            resolve()
          })
        })
      }
    },
    getSign({ commit, state }, id) {
      // if (state.signs[id] !== undefined) {
        return new Promise((resolve, reject) => {
          axios.post(link + '/forms/anket/sign', { id }).then(response => {
            commit('SAVE_SIGN_LIST', response.data)
            commit('GET_ITEMS_PER_PAGE')
            resolve()
          })
        })
     // }
    },
    getBigSignList({ commit, state }, data) {
        return new Promise((resolve, reject) => {
          axios.post(link + '/forms/result/process/' + data.id, { type: data.type, page: (data.page) ? data.page : 1 }).then(response => {
            state.anketId = parseInt(data.id)
            commit('GET_ANKET_BY_ID')
            commit('SAVE_BIG_SIGN_LIST', response.data)
            commit('GET_ITEMS_PER_PAGE')
            resolve()
          })
        })
    },
    getBigSignSvod({ commit, state }, data) {
        return new Promise((resolve, reject) => {
          axios.post(link + '/forms/result/process/' + data.id, { type: data.type }).then(response => {
            commit('SAVE_BIG_SIGN_SVOD', response.data)
            resolve()
          })
        })
    },
    getRegions({ state, commit }) {
      if (!state.regions.length) {
        axios.post(link + '/forms/regions/get').then(response => {
          commit('GET_REGIONS', response.data)
        })
      }
    },
    getEcc({ state, commit }) {
      if (!state.regions.length) {
        axios.post(link + '/forms/ecc/get').then(response => {
          commit('GET_ECC', response.data)
        })
      }
    },
    changeAnketId({ state, commit }, number) {
      commit('CHANGE_ANKET_ID', number)
    },
    setAnketTitle({ commit, state }, value) {
      state.ankets[state.anketNum].pages[state.anketPageNumber].items[value.number].name = value.name
      db.store.update(state.anketId, { pages: state.ankets[state.anketNum].pages }).then(function () {
        commit('CHANGE_ANKET', state.ankets[state.anketNum].pages[state.anketPageNumber].items)
      })
    },
    setAnketVariants({ commit, state }, value) {
      state.ankets[state.anketNum].pages[state.anketPageNumber].items[value.number].variants = value.variants
      db.store.update(state.anketId, { pages: state.ankets[state.anketNum].pages }).then(function () {
        commit('CHANGE_ANKET', state.ankets[state.anketNum].pages[state.anketPageNumber].items)
      })
    },
    refreshAnketItem({ commit, state }, item) {
      state.ankets[state.anketNum].pages[state.anketPageNumber].items[item.number] = item
      db.store.update(state.anketId, { pages: state.ankets[state.anketNum].pages[state.anketPageNumber] }).then(function () {
        commit('CHANGE_ANKET', state.ankets[state.anketNum].pages[state.anketPageNumber].items)
      })
    },
    deleteItem({ commit, state }, i) {
      state.ankets[state.anketNum].pages[state.anketPageNumber].items.splice(i, 1)
      db.store.update(state.anketId, { pages: state.ankets[state.anketNum].pages }).then(function () {
        commit('CHANGE_ANKET', state.ankets[state.anketNum].pages[state.anketPageNumber].items)
      })
    },
    addItem({ commit, state }, item) {
      state.ankets[state.anketNum].pages[state.anketPageNumber].items.splice(item.number, 0, item.content)
      db.store.update(state.anketId, { pages: state.ankets[state.anketNum].pages }).then(function () {
        commit('CHANGE_ANKET', state.ankets[state.anketNum].pages[state.anketPageNumber].items)
      })
    },
    changeOrder({ commit, state }, item) {
      commit('CHANGE_ANKET', item)
    },
    addPage({ commit, state }) {
      const page = {
        id: state.anketPageNumber++,
        items: [
          {
            id: 1,
            name: 'Вопрос 1',
            type: 'textfield',
            conf: {}
          }
        ]
      }
      commit('ADD_PAGE', page)
    },
    delPage({ commit, state }, number) {
      commit('DEL_PAGE', number)
    },
    saveAnket({ commit, state }, name) {
      axios.post(link + '/forms/anket/save', { anket: state.ankets[state.anketNum], name: name, user: state.auth.userId }).then(response => {
        commit('SAVE_ANKET', response.data)
        commit('GET_ANKET_BY_NUM')
      })
    },
    clearCache({ commit, state }) {
      commit('CLEAR')
    },
    deleteAnket({ commit, state }, number) {
      if (state.ankets[number].id > 0) axios.post(link + '/forms/anket/delete', { id: state.ankets[number].id })
      db.store.where({ id: state.ankets[number].id }).delete()
      commit('DELETE_ANKET', number)
    },
    synchronize({ commit, state }, number) {
      return new Promise((resolve, reject) => {
        axios.post(link + '/forms/anket/get', { user: state.auth.userId }).then(response => {
          if (response.data.length) {
            response.data.forEach(item => {
              db.store.put({ id: item.id, name: item.name, slug: item.slug, status: item.status, pages: item.pages, signCount: item.signCount })
            })
            db.store.toArray().then((localResponse) => {
              commit('GET_ANKETS', localResponse)
            })
          } else {
            db.store.toArray().then((localResponse) => {
              if (localResponse.length) commit('GET_ANKETS', localResponse)
                else commit('GET_ANKETS', JSON.parse(JSON.stringify(state.anketBlank)))
            })
          }
          resolve()
        })
      })
    },
    setItemsPerPage({ commit, state }, number) {
      commit('SET_ITEMS_PER_PAGE', number)
    }
  },
  modules: {
    sign: signStore,
    auth: authStore
  }
})
