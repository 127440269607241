<template>
  <v-row>
    <v-col class="mt-0 ml-3 mr-3 pl-8 pr-8 pt-2 pb-0">

      <h1 class="item_title mt-3 mb-6 ml-0">{{data.name}}</h1>

      <v-combobox
        label="Напишите часть названия"
        clearable
        filled
        single-line
        :rules="rules"
        :items="items"
        v-model="selItem"
        @change="saveData"
      ></v-combobox>
    </v-col>

  </v-row>
</template>

<script>

    export default {
        name: 'EccField',
        props: ['item', 'number'],
        watch: {
            item: function (item) {
                this.data = item
            },
            anketaId: function(newVal) {
                if (!newVal) this.selItem = null
            }
        },
        data () {
            return {
                rules: [],
                data: this.item,
                date: null,
                menu: false,
                selItem: null
            }
        },
        methods: {
            saveData: function () {
                this.$store.dispatch('saveSignItem', { id: this.item.id, value: this.selItem, type: 'eccfield', check: true })
            }
        },
        computed: {
            items: function () {
                return this.$store.getters.allEcc.map((item) => {
                    return item.name
                })
            },
            val: function() {
                const a = this.$store.getters.currentSign.find(item => { return (item.id === this.data.id) })
                if (a) return a.value
                else return null
            },
            anketaId: function() {
                return this.$store.getters.currentSignUnique
            }
        },
        mounted() {
            this.selItem = this.val
        }
    }
</script>

<style scoped>

</style>
