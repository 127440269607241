import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Dexie from 'dexie'
Vue.use(Vuex)

axios.defaults.withCredentials = true

const link = (process.env.NODE_ENV === 'development') ? 'http://localhost/kodo/kodo.api' : 'https://api.kodo.kkr.ru'

export const db = new Dexie('kodoformDB')
db.version(1).stores({
  signs: '++id, number'
})

export const signStore = {

  state: {
    signAnket: [],
    sign: [],
    signId: 0,
    signUnique: null
  },
  getters: {
    currentSign: state => state.sign,
    currentSignUnique: state => state.signUnique,
    currentSignAnket: state => state.signAnket,
    currentSignAnketId: state => state.signId,
    currentSignAnketSlug: state => state.signAnket.slug
  },
  mutations: {
    GET_SIGN_ANKET: (state, anket) => {
      Vue.set(state, 'signAnket', anket)
      state.signUnique = localStorage.getItem('kodo-sign-id-' + state.signAnket.id)
    },
    GET_SIGN_LIST: (state, list) => {
      if (list) {
        Vue.set(state, 'sign', list)
      } else {
        if (localStorage.getItem('kodo-sign-' + state.signAnket.id)) Object.assign(state.sign, JSON.parse(localStorage.getItem('kodo-sign-' + state.signAnket.id)))
      }
    },
    GET_SIGN_ID: (state, id) => {
      if (id) state.signId = id
    },
    SAVE_SIGN_ITEM: (state, data) => {
      const a = state.sign.find(item => {
        if (item.id === data.id) {
          item.value = data.value
          item.type = data.type
          item.check = data.check
          return true
        } else return false
      })
      if (!a) state.sign.push(data)
      db.signs.put({ id: state.signAnket.id, data: state.sign })
      localStorage.setItem('kodo-sign-' + state.signAnket.id, JSON.stringify(state.sign))
    },
    SET_UID: (state, unique) => {
      state.signUnique = unique
    },
    SAVE_SIGN: (state, unique) => {
      localStorage.setItem('kodo-sign-' + state.signAnket.id, JSON.stringify(state.sign))
      state.signUnique = unique
      localStorage.setItem('kodo-sign-id-' + state.signAnket.id, unique)
    },
    CLEAR_SIGN: (state) => {
      Vue.set(state, 'signUnique', null)
      Vue.set(state, 'sign', [])
      Vue.set(state, 'signId', 0)
      localStorage.removeItem('kodo-sign-' + state.signAnket.id)
      localStorage.removeItem('kodo-sign-id-' + state.signAnket.id)
    }
  },
  actions: {
    getAnketBySlug({ state, commit, rootState }, slug) {
      return new Promise((resolve, reject) => {
        if (!rootState.anketItems.length) {
          axios.post(link + '/forms/items/get').then(response => {
            commit('GET_ANKET_ITEMS', response.data)
          })
        }

        if (!state.signAnket.length) {
          axios.post(link + '/forms/anket/slug/get', { slug: slug.slug }).then(response => {
            if (response.data) {
              commit('GET_SIGN_ANKET', response.data)
              console.log(slug.id)
              if (!slug.id) slug.id = state.signUnique
              else commit('SET_UID', slug.id)
              axios.post(link + '/forms/sign/code/get', { id: slug.id }).then(response => {
                commit('GET_SIGN_LIST', response.data)
                resolve()
              })
            } else commit('GET_SIGN_ANKET', null)
          })
        } else {
          commit('GET_SIGN_ANKET', null)
          resolve()
        }
      })
    },
    saveSignItem({ commit }, data) {
      commit('SAVE_SIGN_ITEM', data)
    },
    saveFile({ commit }, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('id', data.id)
        formData.append('unique', data.idUnique)
        formData.append('file', data.value)

        axios.post(link + '/forms/sign/file/save', formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          resolve(response)
        })
      })
    },
    saveSign({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.post(link + '/forms/sign/save', { sign: state.sign, sign_id: state.signUnique, anket_id: state.signAnket.id }).then(response => {
          commit('SAVE_SIGN', response.data)
          resolve()
        })
      })
    },
    newSign({ state, commit }) {
      commit('CLEAR_SIGN')
    },
    resultByAnket({ state, commit, rootState }) {
      rootState.anket.pages.forEach(page => {

      })
    }
  }
}
